<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader title="User Master">
        <template #subTitle>
          <a-input
            v-model:value="searchValue"
            @change="handleSearch"
            placeholder="Search by Name"
          >
            <template #suffix>
              <sdFeatherIcons type="search" size="16" />
            </template>
          </a-input>
        </template>
        <template #buttons>
          <a-button
            @click="showModal"
            class="btn-add_new"
            size="default"
            type="primary"
            key="1"
          >
            <a to="#">+ Add New User</a>
          </a-button>
        </template>
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>
  <Main>
    <sdCards headless>
      <a-row :gutter="25">
        <a-col :xs="24">
          <TopToolBox style="margin-bottom: 0px">
            <a-row :gutter="15" class="justify-content-left">
              <a-col :xxl="14" :lg="16" :xs="24">
                <div class="table-toolbox-menu">
                  <span class="toolbox-menu-title"> Status : </span>&nbsp;
                  <a-radio-group @change="handleChangeForFilter">
                    <a-radio-button value="">All</a-radio-button>
                    <a-radio-button value="active"> Active </a-radio-button>
                    <a-radio-button value="blocked"> Blocked </a-radio-button>
                    <a-radio-button value="deactivate">
                      Deactivate
                    </a-radio-button>
                  </a-radio-group>
                </div>
              </a-col>
            </a-row>
          </TopToolBox>
        </a-col>
        <a-col :md="24">
          <sdCards headless>
            <UserTableStyleWrapper>
              <div class="contact-table">
                <TableWrapper class="table-responsive">
                  <a-table
                    :dataSource="usersTableData"
                    :columns="usersTableColumns"
                    :pagination="{
                      defaultPageSize: 10,
                      total: usersTableData.length,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    }"
                  />
                </TableWrapper>
              </div>
            </UserTableStyleWrapper>
          </sdCards>
        </a-col>
      </a-row>
      <a-modal
        :type="localState.modalType"
        title="User Information"
        :visible="localState.visible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <BasicFormWrapper>
            <a-form
              name="contact"
              :rules="rules"
              :model="addFormState"
              @finish="handleOk"
              :layout="addFormState.layout"
            >
              <a-form-item label="First Name" name="f_name">
                <a-input
                  v-model:value="addFormState.f_name"
                  placeholder="Input Name"
                />
              </a-form-item>
              <a-form-item label="Last Name" name="l_name">
                <a-input
                  v-model:value="addFormState.l_name"
                  placeholder="Input Name"
                />
              </a-form-item>
              <a-form-item label="Email Address" name="email">
                <a-input
                  v-model:value="addFormState.email"
                  placeholder="name@example.com"
                />
              </a-form-item>
              <a-form-item name="designation" label="Role">
                <a-select
                  v-model:value="addFormState.designation"
                  style="width: 100%"
                >
                  <a-select-option
                    v-for="item in roledrop"
                    v-bind:key="item.value"
                    :value="item.value"
                  >
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item name="company" label="Company Name">
                <a-input
                  v-model:value="addFormState.company"
                  placeholder="Company Name"
                />
              </a-form-item>
              <a-form-item label="Password" name="password">
                <a-input type="password" v-model:value="addFormState.password" />
              </a-form-item>
              <a-button
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
              >
                Add New User
              </a-button>
            </a-form>
          </BasicFormWrapper>
        </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="User Edit"
        :visible="localState.editVisible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <BasicFormWrapper>
              <a-form
                name="contactEdit"
                :rules="rules"
                :model="editFormState"
                @finish="handleEditOk"
                :layout="addFormState.layout"
              >
                <a-form-item label="First Name" name="f_name">
                  <a-input
                    v-model:value="editFormState.f_name"
                    placeholder="Input Name"
                  />
                </a-form-item>
                <a-form-item label="Last Name" name="l_name">
                  <a-input
                    v-model:value="editFormState.l_name"
                    placeholder="Input Name"
                  />
                </a-form-item>
                <a-form-item label="Email Address" name="email">
                  <a-input
                    v-model:value="editFormState.email"
                    placeholder="name@example.com"
                  />
                </a-form-item>
                <a-form-item name="designation" label="Role">
                  <a-select
                    v-model:value="editFormState.designation"
                    style="width: 100%"
                  >
                    <a-select-option
                      v-for="(item, index) in roledrop"
                      v-bind:key="index"
                      v-bind:value="item.value"
                    >
                      {{ item.title }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item name="status" label="status">
                  <a-select
                    v-model:value="editFormState.status"
                    style="width: 100%"
                  >
                    <a-select-option value="active">Active</a-select-option>
                    <a-select-option value="blocked">Blocked</a-select-option>
                    <a-select-option value="deactivate"
                      >Deactivate</a-select-option
                    >
                  </a-select>
                </a-form-item>
                <a-form-item name="company" label="Company Name">
                  <a-input
                    v-model:value="editFormState.company"
                    placeholder="Company Name"
                    disabled
                  />
                </a-form-item>
                <a-button
                  htmlType="submit"
                  size="default"
                  type="primary"
                  key="submit"
                >
                  Update Contact
                </a-button>
              </a-form>
            </BasicFormWrapper>
          </AddUser>
        </div>
      </a-modal>
    </sdCards>
  </Main>
</template>

<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  TopToolBox,
} from "./style";
import {
  Main,
  CardToolbox,
  BasicFormWrapper,
  TableWrapper,
} from "../../../styled";
import { AddUser } from "../rolemaster/userstyle";
import { useStore } from "vuex";
import { computed, reactive, ref, onMounted } from "vue";
// import { FormValidationWrap } from './form/Style';
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";

const UserListTable = {
  name: "UserListTable",
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
    TopToolBox,
  },
  props: {
    tabledata: Object,
  },

  setup(props, { emit }) {
    const router = useRouter();
    const { push } = useRouter();
    var { state, dispatch } = useStore();
    state.UserMaster.data = JSON.parse(JSON.stringify(props)).tabledata;
    const users = computed(() => state.UserMaster.data);
    var tabledata = JSON.parse(JSON.stringify(props)).tabledata;
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    var roledrop = ref();
    var currentuser = JSON.parse(localStorage.getItem("user"));
    // console.log(state.UserMaster.data);
    onMounted(() => {
      getdropdata();
    });

    const getdropdata = () => {
      // console.log(currentuser);
      dispatch("getroledropdata").then(
        (response) => {
          // console.log(response);
          roledrop.value = response.data.roledata;
          // console.log(roledrop);
        },
        (error) => {
          console.log(error);
        }
      );
    };

    const addFormState = reactive({
      company_id: currentuser.company_id,
      f_name: "",
      l_name: "",
      email: "",
      designation: "",
      password: "",
      company: currentuser.c_name,
      layout: "vertical",
    });
    const editFormState = reactive({
      f_name: "",
      l_name: "",
      email: "",
      designation: "",
      status: "",
      company: currentuser.c_name,
      // time: '',
      // img: '',
      // stared: '',
    });

    const rules = {
      f_name: [
        {
          required: true,
          message: "Please input First name",
          trigger: "blur",
        },
      ],
      l_name: [
        {
          required: true,
          message: "Please input Last name",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          type: "email",
          message: "Please input a valid email!",
          trigger: "blur",
        },
      ],
      designation: [
        {
          required: true,
          type: "number",
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: "primary",
      url: null,
    });
    const searchValue = ref("");

    const handleSearch = () => {
      var data = {};
      data.serchtext = searchValue.value;
      data.userdata = JSON.parse(JSON.stringify(props)).tabledata;
      dispatch("usermasterSearchData", data);
    };

    const handleChangeForFilter = (e) => {
      var data = {};
      data.userdata = JSON.parse(JSON.stringify(props)).tabledata;
      data.column = "status";
      data.serchtext = e.target.value;
      // console.log(data);
      dispatch("dataFilter", data);
    };

    const showModal = () => {
      localState.visible = true;
    };

    const showEditModal = (data) => {
      localState.editVisible = true;
      editFormState.f_name = data.f_name;
      editFormState.l_name = data.l_name;
      editFormState.id = data.id;
      editFormState.email = data.email;
      editFormState.status = data.status;
      editFormState.designation = data.role_id;
      editFormState.company = data.c_name;
    };

    const onCancel = () => {
      localState.visible = false;
      localState.editVisible = false;
      localState.update = {};
    };

    const handleOk = (values) => {
      // console.log(addFormState);
      dispatch("userAddData", addFormState).then(
        (response) => {
          if (response.status == 200) {
            // console.log(response.data.data);
            location.reload();
            Notification["success"]({
              message: "Succsess!",
              description: "User Added.",
            });
            emit("eventname", "emitest");
            onCancel();
            
          } else {
            onCancel();
          }
        },
        (error) => {
          if (error.status == 201) {
          Notification["error"]({
              message: "Error!",
              description: "The email has already been taken.",
            });
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Something is wrong.",
            });
          }
        }
      );
    };

    const handleEditOk = (values) => {
      const updateUsers = users.value;
      // console.log(editFormState);
      // console.log(updateUsers);
      dispatch("userUpdateData", editFormState).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Succsess!",
              description: "User Updated.",
            });
            emit("eventname", "emitest");
            // emit('eventname');
            onCancel();
            // router.go();
          } else {
            onCancel();
          }
          // orgdata.value = null;
          // xeroconect.value = false;
          // router.push('/users');
        },
        (error) => {
          if (error.status == 201) {
            Notification["error"]({
              message: "Error!",
              description: "The email has already been taken.",
            });
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Something is wrong.",
            });
          }
          // router.push('/');
          // xeroconect.value = false;
        }
      );
    };

    const handleCancel = () => {
      onCancel();
    };

    const onHandleDelete = (id) => {
      // const value = users.value.filter((item) => item.id !== id);
      // console.log(id);
      // console.log(users.value);
      dispatch("userDeleteData", id, users.value).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Succsess!",
              description: "User Deleted.",
            });
            emit("eventname", "emitest");
            onCancel();
          } else {
            onCancel();
          }
        },
        (error) => {
          console.log(error);
          Notification["error"]({
            message: "Error!",
            description: error,
          });
        }
      );
    };

    var usersTableData = computed(() =>
      state.UserMaster.data
        .sort((a, b) => {
          return b.time - a.time;
        })
        .map((user) => {
          const {
            id,
            profile_pic,
            fullname,
            f_name,
            l_name,
            email,
            c_name,
            role_name,
            status,
          } = user;

          return {
            key: id,
            user_pic: (
              <figure>
                <img
                  style={{ width: "40px" }}
                  src={`${API_ENDPOINT + profile_pic}`}
                  alt=""
                />
              </figure>
            ),
            username: fullname,
            email: email,
            company: c_name,
            position: role_name,
            status: <span class={`status-text ${status}`}>{status}</span>,
            action: (
              <Action class="table-actions">
                <sdDropdown
                  class="wide-dropdwon"
                  overlay={
                    <>
                      <a onClick={() => showEditModal(user, id)} to="#">
                        <span>Edit</span>
                      </a>
                      <a-popconfirm
                        title="Are you sure delete this user?"
                        ok-text="Yes"
                        cancel-text="No"
                        onConfirm={() => onHandleDelete(id)}
                      >
                        <a href="#">Delete</a>
                      </a-popconfirm>
                    </>
                  }
                  action={["click"]}
                >
                  <a-button class="btn-icon" type="info" to="#" shape="circle">
                    <sdFeatherIcons type="more-vertical" size={16} />
                  </a-button>
                </sdDropdown>
              </Action>
            ),
          };
        })
    );

    const usersTableColumns = [
      {
        title: "Profile Pic",
        dataIndex: "user_pic",
      },
      {
        title: "User Name",
        dataIndex: "username",
        key: "username",
        sorter: (a, b) => a.email.length - b.email.length,
        // sorter: (a, b) => a.username.children[0].children.outerText.length - b.username.children[0].children.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Company",
        dataIndex: "company",
        key: "company",
        sorter: (a, b) => a.company.length - b.company.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Position",
        dataIndex: "position",
        key: "position",
        sorter: (a, b) => a.position.length - b.position.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };

    return {
      rowSelection,
      usersTableColumns,
      usersTableData,
      handleCancel,
      handleEditOk,
      handleOk,
      onCancel,
      showEditModal,
      showModal,
      handleSearch,
      localState,
      users,
      searchValue,
      addFormState,
      rules,
      editFormState,
      roledrop,
      handleChangeForFilter,
    };
  },
};

export default UserListTable;
</script>
 
<template>
    <div class="jyvLkD ant-alert ant-alert-info ant-alert-with-description ant-alert-no-icon" data-show="true"  v-if="text != ''">
        <!---->
        <div class="ant-alert-content">
            <div class="ant-alert-message">Description:</div>
            <div class="ant-alert-description"><span v-html="text"></span></div>
        </div>
        <!---->
    </div>
    <br>
</template>
<script>
  export default {
    name: 'Description',
    props: {
        text: { required: true, type: String },
    }
  };
</script>
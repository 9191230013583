<template>
  <sdCards title="Nominal" size="default" style="height: 95% !important">
    <div class="notification">
      <ActivityContents>
        <ul class="activity-list">
          
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_vat">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Inconsistent VAT Codes
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Identify Suppliers/Customers contact name wise where multiple invoices are booked with multiple VAT codes.(Bank Transactions & Invoices)</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['inconsistent_vat'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["inconsistent_vat"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_nominal">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Inconsistent Nominal Codes
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Identify Suppliers/ Customers contact name wise where multiple invoices are booked with multiple nominal codes.(Bank Transactions & Invoices)</li>
                        </ul>
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['inconsistent_nominal'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["inconsistent_nominal"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li> 
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_nominalvat">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Inconsistent Nominal Codes with VAT Codes
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Nominal Codes where Multiple VAT Codes are booked.(Bank Transactions & Invoices)</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['inconsistent_Nominal_with_vat'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["inconsistent_Nominal_with_vat"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_transnominal">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        NO VAT/ZERO RATED /EXEMPT VAT entries for review
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>List of Transactions with No VAT, Exempt VAT, Export VAT, Zero Rated VAT.</li>
                          <li>Account Code  having Overheads, Expense, Revenue, Fixed & Current Liabilities for Suspense entry.
                            (Bank Transactions & Invoices)</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['incorrect_vat'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["incorrect_vat"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          
          <!-- <li class="activity-list__single">
            <div class="activity-content">
               <router-link to="xero_pivotanalysis">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Variance Analysis Pivot Table
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Assets having Credit Balances.</li>
                          <li>Liabilities having Debit Balances.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['variance_analysis_pivot_table'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['variance_analysis_pivot_table']}}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
            <span class="activity-icon success">
              <sdFeatherIcons type="check-circle" size="24" />
            </span>
            </template>
          </li> -->
          <!--<li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_vatasset">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        VAT Mismatch
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Assets having Credit Balances.</li>
                          <li>Liabilities having Debit Balances.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['vat_mismatch'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["vat_mismatch"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>-->

          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_vatjournal">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Manual Journals with VAT
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Manual Journals with VAT codes used.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['manual_journals_vat'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["manual_journals_vat"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_supplier">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        New Contact Name
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>New Contact name created within report range.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['incorrect_contact'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["incorrect_contact"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_hmrc">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        HMRC Payments Report
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>HMRC Payments Report for Account Code Review- Based on Original Green Bank Description.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['xero_hmrc'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["xero_hmrc"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_description">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Account Code Review By Descriptions
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Account Code Review By Descriptions(With/Without Descriptions & Descriptions containing Online Store Names)</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['xero_descriptions'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["xero_descriptions"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <!-- <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_lateentry">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Late Entries
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Assets having Credit Balances.</li>
                          <li>Liabilities having Debit Balances.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['late_entries'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['late_entries'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
            <span class="activity-icon success">
              <sdFeatherIcons type="check-circle" size="24" />
            </span>
            </template>
          </li> -->

          <!-- <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="xero_lateentry">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Tracking Categories
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Assets having Credit Balances.</li>
                          <li>Liabilities having Debit Balances.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['tracking_categories'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data['tracking_categories'] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
            <span class="activity-icon success">
              <sdFeatherIcons type="check-circle" size="24" />
            </span>
            </template>
          </li> -->
        </ul>
      </ActivityContents>
    </div>
  </sdCards>
</template>

<script>
import { computed, ref, defineComponent, onMounted } from "vue";
import {
  UserTableStyleWrapper,
  ActivityContents,
  ProductCard,
} from "../../../style";

export default defineComponent({
  name: "Nominal",
  props: ["data"],
  watch: {
    data: function (newVal, oldVal) {
      // watch it
      // console.log(newVal);
      // console.log(oldVal);
    },
  },
  components: { ActivityContents },

  setup() {
    return {};
  },
  methods: {},
});
</script>

<template>
  <router-view></router-view>
</template>

<script>
import { Main } from "../../../../styled";
import { defineComponent } from 'vue';
export default defineComponent({
  name: "Dynamicindex",
  components: {
    Main,
  },
  data() {
    return {};
  },
});
</script>

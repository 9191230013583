<template>
  <CardToolbox>
    <ContactPageheaderStyle>
      <sdPageHeader title="Admin Panel">
        <template #subTitle> 
          <a-input
            v-model:value="searchValue"
            @change="handleSearch"
            placeholder="Search by Name"
          >
            <template #suffix>
              <sdFeatherIcons type="search" size="16" />
            </template>
          </a-input> 
        </template>
        <template #buttons>
          <!--<a-button
            @click="showModal"
            class="btn-add_new"
            size="default"
            type="primary"
            key="1"
          >
            <a to="#">+ Add New Role</a>
          </a-button>-->
        </template>
      </sdPageHeader>
    </ContactPageheaderStyle>
  </CardToolbox>

  <Main>
    <a-row :gutter="25">
      <a-col :md="24">
        <sdCards headless>
          <UserTableStyleWrapper>
            <div class="contact-table">
              <TableWrapper class="table-responsive">
                <a-table
                  :dataSource="usersTableData"
                  :columns="usersTableColumns"
                  :pagination="{
                    defaultPageSize: 10,
                    total: usersTableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }"
                />
              </TableWrapper>
            </div>
          </UserTableStyleWrapper>
        </sdCards>
      </a-col>
      <a-modal
        :type="localState.modalType"
        title="Role Information"
        :visible="localState.visible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <FormValidationWrap>
              <BasicFormWrapper>
                <a-form
                  name="contact"
                  :rules="rules"
                  :model="addFormState"
                  @finish="handleOk"
                  :layout="addFormState.layout"
                >
                  <a-form-item label="Role Name" name="role_name">
                    <a-input
                      v-model:value="addFormState.role_name"
                      placeholder="Eneter Role Name"
                    />
                  </a-form-item>
                  <a-form-item name="permission" label="Permission">
                    <a-select
                      mode="multiple"
                      :default-value="addFormState.permission"
                      v-model:value="addFormState.permission"
                      name="permission[]"
                      placeholder="Please select permission"
                    >
                      <a-select-option
                        v-for="item in permissionlist"
                        v-bind:key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-button
                    htmlType="submit"
                    size="default"
                    type="primary"
                    key="submit"
                  >
                    Add New User
                  </a-button>
                </a-form>
              </BasicFormWrapper>
            </FormValidationWrap>
          </AddUser>
        </div>
      </a-modal>
      <a-modal
        :type="localState.modalType"
        title="User Edit"
        :visible="localState.editVisible"
        :footer="null"
        :onCancel="handleCancel"
      >
        <div class="project-modal">
          <AddUser>
            <FormValidationWrap>
              <BasicFormWrapper>
                <a-form
                  name="contactEdit"
                  :rules="rules"
                  :model="editFormState"
                  @finish="handleEditOk"
                  :layout="addFormState.layout"
                >
                  <a-form-item label="Company/Team Name" name="company">
                    <a-input
                      v-model:value="editFormState.company"
                      name="company"
                      placeholder="Input Company/Team Name"
                    />
                  </a-form-item>
                  <a-form-item label="Connection" name="connection">
                    <a-input
                      v-model:value="editFormState.connection"
                      name="connection"
                      placeholder="Input Connection Limit"
                    />
                  </a-form-item>
                  <a-form-item label="User Status" name="status">
                    <a-input
                      v-model:value="editFormState.status"
                      name="status"
                      placeholder="Input Status"
                    />
                  </a-form-item>
                  <a-button
                    htmlType="submit"
                    size="default"
                    type="primary"
                    key="submit"
                  >
                    Update User
                  </a-button>
                </a-form>
              </BasicFormWrapper>
            </FormValidationWrap>
          </AddUser>
        </div>
      </a-modal>
    </a-row>
  </Main>
</template>

<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper } from "./style";
import {
  Main,
  CardToolbox,
  BasicFormWrapper,
  TableWrapper,
} from "../../../styled";
import { AddUser } from "./userstyle";
import { useStore } from "vuex";
import { computed, reactive, ref, onMounted } from "vue";
import { FormValidationWrap } from "./form/Style";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { message, Notification } from "ant-design-vue";

const AdminListTable = {
  name: "AdminListTable",
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    FormValidationWrap,
    AddUser,
    TableWrapper,
    UserTableStyleWrapper,
    ContactPageheaderStyle,
    Action,
  },
  props: {
    tabledata: Object,
  },

  setup(props, { emit }) {
    const router = useRouter();
    const { push } = useRouter();
    var { state, dispatch } = useStore();
    state.AdminMaster.data = JSON.parse(JSON.stringify(props)).tabledata;
    const users = computed(() => state.AdminMaster.data);
    var tabledata = JSON.parse(JSON.stringify(props)).tabledata;
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    var permissionlist = ref(); //added by anil
    var roledrop = ref();

    var currentuser = JSON.parse(localStorage.getItem("user"));
    // console.log(state.UserMaster.data);
    onMounted(() => {
      getPermissionData();
    });

    const getPermissionData = () => {
      dispatch("getPermissionData").then(
        (response) => {
          permissionlist.value = response.data.data;
        },
        (error) => {
          console.log(error);
        }
      );
      // axios
      //       .get(API_ENDPOINT + 'api/auth/permission')
      //       .then((response) => {
      //         permissionlist.value = response.data.data;
      //   });
    };

    

    const addFormState = reactive({
      company_id: currentuser.company_id,
      role_name: "",
      permission: [],
      company: currentuser.c_name, 
      layout: "vertical",
    });
    const editFormState = reactive({
      company: "",
      status: "",
    });

    const rules = {
      role_name: [
        {
          required: true,
          message: "Please enter Role name",
          trigger: "blur",
        },
      ],
      // permission: [
      //   {
      //     required: true,
      //     message: 'Please select permission',
      //     trigger: 'blur',
      //   },
      // ],
    };

    const localState = reactive({
      selectedRowKeys: 0,
      selectedRows: 0,
      visible: false,
      editVisible: false,
      modalType: "primary",
      url: null,
    });
    const searchValue = ref("");

    const handleSearch = () => {
      var data = {};
      data.serchtext = searchValue.value;
      data.userdata = JSON.parse(JSON.stringify(props)).tabledata;
      dispatch("rolemasterSearchData", data);
    };

    const showModal = () => {
      localState.visible = true;
    };

    const showEditModal = (data) => {
      console.log(data);
      localState.editVisible = true;
      editFormState.company = data.c_name;
      editFormState.connection = data.connection_limit;
      editFormState.status= data.status;
      editFormState.id = data.company_id;
    };

    const onCancel = () => {
      localState.visible = false;
      localState.editVisible = false;
      localState.update = {};
    };

    const handleOk = (values) => {
      // console.log(addFormState);
      dispatch("roleAddData", addFormState).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Succsess!",
              description: "Role Added.",
            });
            // message.success(response.data.message);
            emit("eventname", "emitest");
            onCancel();
          } else {
            Notification["error"]({
              message: "Error!",
              description: "The role name has already been taken..",
            });
            // message.error('something is wrong!');
            // onCancel();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    };

    const handleEditOk = (values) => {
      const updateUsers = users.value;
      dispatch("adminUpdateData", editFormState).then(
        (response) => {
          if (response.status == 200) {
            emit("eventname", "emitest");
            Notification["success"]({
              message: "Succsess!",
              description: "User Updated.",
            });
            // message.success('Role Updated Succsessfully!');
            onCancel();
          } else {
            Notification["error"]({
              message: "Error!",
              description: "The role name has already been taken..",
            });
            // onCancel();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    };

    const handleCancel = () => {
      onCancel();
    };

    const onHandleDelete = (id) => {
      const value = [];
      value.data = users.value.filter((item) => item.company_id !== id);
      value.company_id = id;
      dispatch("adminDeleteData", value);
      Notification["success"]({
        message: "Succsess!",
        description: "Company Deleted.",
      });
      
    };

    var usersTableData = computed(() =>
      state.AdminMaster.data
        .sort((a, b) => {
          return b.time - a.time;
        })
        .map((user) => {
          const { 
          id,
          f_name,
          l_name,
          c_name,
          connection_limit,
          xero_tenent_id,
          user_type,status,
          subscription_status,
          clients_connected,
          created_at,
          updated_at,
          db_prefix,
          expiry,
          email,
          company_id,
          open_id,
          xero_user_id 
          } = user;
          return {
            key: id,
            id: id,
            f_name: f_name,
            l_name: l_name,
            name: f_name+" "+l_name,
            c_name: c_name,
            connection_limit: connection_limit,
            clients_connected: clients_connected,
            xero_tenent_id: xero_tenent_id,
            user_type: user_type,
            status: status,
            subscription_status:subscription_status,
            created_at:created_at,
            updated_at:updated_at,
            db_prefix:db_prefix,
            expiry:expiry,
            email:email,
            company_id:company_id,
            open_id:open_id,
            xero_user_id:xero_user_id,
            action: (
              <Action class="table-actions">
                <sdDropdown
                  class="wide-dropdwon"
                  overlay={
                    <>
                      <a onClick={() => showEditModal(user, id)} to="#">
                        <span>Edit</span>
                      </a>
                      <a-popconfirm
                        title="Are you sure delete this user?"
                        ok-text="Yes"
                        cancel-text="No"
                        onConfirm={() => onHandleDelete(company_id)}
                      >
                        <a href="#">Delete</a>
                      </a-popconfirm>
                    </>
                  }
                  action={["click"]}
                >
                  <a-button class="btn-icon" type="info" to="#" shape="circle">
                    <sdFeatherIcons type="more-vertical" size={16} />
                  </a-button>
                </sdDropdown>
              </Action>
            ),
          };
        })
    );

    const usersTableColumns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id.length - b.id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Company/Team",
        dataIndex: "c_name",
        key: "c_name",
        sorter: (a, b) => a.c_name.length - b.c_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Connection Limit",
        dataIndex: "connection_limit",
        key: "connection_limit",
        sorter: (a, b) => a.connection_limit.length - b.connection_limit.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Clients Connected",
        dataIndex: "clients_connected",
        key: "clients_connected",
        sorter: (a, b) => a.clients_connected.length - b.clients_connected.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "User Type",
        dataIndex: "user_type",
        key: "user_type",
        sorter: (a, b) => a.user_type.length - b.user_type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Subscription Status",
        dataIndex: "subscription_status",
        key: "subscription_status",
        sorter: (a, b) => a.subscription_status.length - b.subscription_status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Subscription Ends",
        dataIndex: "expiry",
        key: "expiry",
        sorter: (a, b) => a.expiry.length - b.expiry.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "User Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => a.status.length - b.status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };

    return {
      rowSelection,
      usersTableColumns,
      usersTableData,
      handleCancel,
      handleEditOk,
      handleOk,
      onCancel,
      showEditModal,
      showModal,
      handleSearch,
      localState,
      users,
      searchValue,
      addFormState,
      rules,
      editFormState,
      roledrop,
      permissionlist,
    };
  },
};

export default AdminListTable;
</script>
 

<template>
  <sdCards title="Dynamic" size="default" style="height: 95% !important">
    <div class="notification">
      <ActivityContents>
        <ul class="activity-list">
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="/xero_Fainvoices">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        FA Invoices below XX Value
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Select Fixed Account Codes Put Manual Amount and Search FA invoices below that amount
All the Bank Transactions & Invoices Captured .</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <!-- <span class="activity-icon danger"> -->
              <sdFeatherIcons type="chevron-down" size="24" />
            <!-- </span> -->
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="/supplier-invoice">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                       O/S Invoices with XX Suppliers
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>This will give outstanding Invoice according to selected Supplier Name.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <!-- <span class="activity-icon danger"> -->
              <sdFeatherIcons type="chevron-down" size="24" />
            <!-- </span> -->
          </li>
         <!--  <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="/xero_prepayments">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Potential Prepayment Report
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Identify Transactions where Narration contains a wordings annual, yearly, Quarter or “to” or “-”.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
              <sdFeatherIcons type="chevron-down" size="24" />
            
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="/xero_accrual">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Potential Accrual Report
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Provide Monthly Amounts Supplier Wise report according to selected account code.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
              <sdFeatherIcons type="chevron-down" size="24" />
            
          </li>-->
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="/value-invoice">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        PI/SI Invoices with XX Value
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>This will provide List of Invoices having XX Value and Above.</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <!-- <span class="activity-icon danger"> -->
              <sdFeatherIcons type="chevron-down" size="24" />
            <!-- </span> -->
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="/da_invoices">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Transaction with Director Name
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Report for Spend Money, Purchase Invoices, etc. according to selected Director name(Bank Transactions & Invoices Captured).</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <!-- <span class="activity-icon danger"> -->
              <sdFeatherIcons type="chevron-down" size="24" />
            <!-- </span> -->
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="/xero_Entertainment">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Entertainment Expenses
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Entertainment Expense 0%, 100%, Hotel Expenses, Refreshments, & Subsistence’s according to selected Nominal codes(All the Bank Transactions & Invoices Captured).</li>
                        </ul>
                      </span>
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <!-- <span class="activity-icon danger"> -->
              <sdFeatherIcons type="chevron-down" size="24" />
            <!-- </span> -->
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="/vat_late">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Vat Late Claims
                      </h4>
                      <!-- <span class="tooltip1__content">
                        <ul>
                          <li>.</li>
                        </ul>
                      </span> -->
                    </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <!-- <span class="activity-icon danger"> -->
              <sdFeatherIcons type="chevron-down" size="24" />
            <!-- </span> -->
          </li>
        </ul>
      </ActivityContents>
    </div>
  </sdCards>
</template>

<script>
import { computed, ref, defineComponent, onMounted } from "vue";
import {
  UserTableStyleWrapper,
  ActivityContents,
  ProductCard,
} from "../../../style";

export default defineComponent({
  name: "Dynamic",
  props: ["data"],
  watch: {
    data: function (newVal, oldVal) {
      // watch it
      // console.log(newVal);
      // console.log(oldVal);
    },
  },
  components: { ActivityContents },

  setup() {
    return {};
  },
  methods: {},
});
</script>

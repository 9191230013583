<template>
  <sdPageHeader>
    <template #title>
      <div class="product-single-description">
        <sdHeading class="product-single-title" as="h1" style="margin: 0px">
         Payment Mode of Prevoiusly Paid Invoices
        </sdHeading>
        <span style="margin: 0px; font-size: 16px; font-weight: 400">
          Last Sync:
          {{
            orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
          }}
        </span>
      </div>
      <a-row :gutter="25">
        <a-col>
          <div class="setting-card-title">
            <sdHeading as="h5" style="font-weight: 500">
              Transactions Between&nbsp;
              <sdFeatherIcons type="calendar" size="16" />&nbsp;
              <span style="margin: 0px; font-size: 16px; font-weight: 400">
                {{ orgdata.syncdate }}
              </span>
              <a-range-picker
                v-if="orgdata.lastsync == 'not_synced'"
                style="padding-top: 10px !important"
                @change="DateSelect"
                :defaultValue="selectrange"
              />
              &nbsp;
              <a-button
                size="medium"
                type="primary"
                v-on:click="xerosync()"
                v-if="orgdata.lastsync == 'not_synced'"
              >
                <sdFeatherIcons type="refresh-cw" size="14" />
                Sync Now
              </a-button>
            </sdHeading>
          </div>
        </a-col></a-row
      >
      </template>
    <template v-slot:buttons>
      <ProjectSorting>
        <div class="project-sort-bar">
          <div class="project-sort-group">
            <div class="sort-group">
              <a-select @change="onSorting" defaultValue="Active">
                <a-select-option value="Active">Active</a-select-option>
                <a-select-option value="Ignored">Dismissed</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </ProjectSorting>
    </template>
  </sdPageHeader>
  <Main>
    <Description text="Report showing payment mode of previously paid invoices for current outstanding invoices" />
    <sdCards headless v-if="loading">
      
      <div class="spin">
        <a-spin /></div
    ></sdCards>
    <sdCards headless v-else>
      <template v-if="selectedoption == 'Active'">
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('B')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                      Dismiss</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :headers="table_headers"
                :data-source="TableData"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                <template v-slot:expandedRowRender="record">
                  <a-table
                    class="ant-table-striped"
                    :data-source="record.record.linedata"
                    :columns="linecolumns"
                    :pagination="false"
                    :rowKey="record.record.linedata.id"
                  >
                  </a-table>
                </template>
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('A')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                      Active</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                :data-source="ignoreTableData"
                :columns="TableColumns"
                
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                <template v-slot:expandedRowRender="record">
                  <a-table
                    :data-source="record.record.linedata"
                    :columns="linecolumns"
                    :pagination="false"
                    :rowKey="record.record.linedata.id"
                  >
                  </a-table>
                </template>
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
    </sdCards>
  </Main>
</template> 
<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  ProjectSorting,
} from "../../style";
import { defineComponent } from "vue";
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from "../../../../styled";
import { dispatch, useStore } from "vuex";
import {
  defineAsyncComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Description from "./../Description.vue";
export default defineComponent({ 
  name: "xero_modes",
  components: {
    Main,
    Description,
    BasicFormWrapper,
    CardToolbox,
    UserTableStyleWrapper,
    TableWrapper,
    ProjectSorting,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    var selectedOption1 = "None";
    const { dispatch, state } = useStore();
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    let controller = null;
    onMounted(() => {
      getdata();
    });

    const addFormState = reactive({
      choice: "Select The Option for Allocation",
      search_text: "",
      layout: "vertical",
    });

    const rules = {
      search_text: [
        {
          required: true,
          message: "Search Here....",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };
    
    const handleSearch = (values) => {
      let substring = addFormState.search_text;
      state.XeroMode.data = JSON.parse(localStorage.getItem("search_data"));
      let xero_get_datas = [];
      (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
        let search_match = false;
        search_match = (((data.tax_type).toLowerCase()).includes(substring.toLowerCase()));
        // console.log(index);
        if(search_match == false){search_match = (((data.contact_name).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.transaction_type).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.tax_type).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.taxtype).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.date)).includes(substring));
        }if(search_match == false){search_match = (((data.subtotal)).includes(substring));
        }if(search_match == false){search_match = (((data.tax_amount)).includes(substring));
        }if(search_match == false){search_match = ((String(data.total)).includes(substring));
        }
        if(search_match == false){
          (state.XeroMode.data).splice(index, 1);
        }else{
          
          xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index]);
        }
      });
      state.XeroMode.data = xero_get_datas;
    };
    
    const openurl = (id,type) => {
      let url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
      switch (type) {
        case 'ACCPAY':
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCREC':
          url = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCRECCREDIT':
          url = 'https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'ACCPAYCREDIT':
          url = 'https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'RECEIVE-OVERPAYMENT':
          url = 'https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'SPEND-OVERPAYMENT':
          url = 'https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'SPEND':
          url = 'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=';
          break;
        case 'RECEIVE':
          url = 'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=';
          break;    
        case 'MJS':
          url = 'https://go.xero.com/Journal/View.aspx?invoiceID=';
          break;     
        default:
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
      }
      window.open(
          url + id,
          "_blank"
        ); 
      
    };

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroMode.data = [];
      localStorage.setItem("search_data",JSON.stringify([]));
      if (selectedItems == "Active") {
        getdata();
      } else {
        getignoredata();
      }
    };
    
    
    const getdata = () => {
      loading.value = true;
      let api_data = [];
      api_data['choice'] = selectedOption1;
      dispatch("getPaymentsModesData", api_data).then(
        (response) => {
          if (response.status == 200) {
            state.XeroMode.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.XeroMode.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          if (error.name === "AbortError") {
          console.log("Request aborted");
          } else {
          state.XeroMode.data = [];
          loading.value = false;
          }
        }
      );
    };

    const getignoredata = () => {
      loading.value = true;
      dispatch("XeroPaymentModeIgnore", "none").then(
        (response) => {
          if (response.status == 200) {
            state.XeroMode.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.XeroMode.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.XeroMode.data = [];
          loading.value = false;
        }
      );
    };

    const onHandleignore = (id, status) => {
      var data = {};
      data.id = id;
      data.status = status;
      dispatch("ignorePaymentModes", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });

            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        }
        // console.log(record)
      ),
    };

    const DateSelect = (date, dateString) => {
      if (selectedoption.value == "Active") {
        loading.value = true;
        dispatch("getPaymentsModesData", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroMode.data = response.data;
              loading.value = false;
            } else {
              state.XeroMode.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroMode.data = [];
            loading.value = false;
          }
        );
      } else {
        loading.value = true;
        dispatch("XeroPaymentModeIgnore", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroMode.data = response.data;
              loading.value = false;
            } else {
              state.XeroMode.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroMode.data = [];
            loading.value = false;
          }
        );
      }
    };

    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("paymentModesIgnoreBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const TableColumns = [
      /*{
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },*/
      {
       title: "Contact Name",
       dataIndex: "supplier",
       key: "supplier",
       sorter: (a, b) => a.supplier.length - b.supplier.length,
      sortDirections: ["descend", "ascend"],
      },
      /*{
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: "total",
        key: "total",
        sorter: (a, b) => a.total.length - b.total.length,
        sortDirections: ["descend", "ascend"],
      },*/
      {
        title: "Paid Invoices",
        dataIndex: "aapaidinvoices",
        key: "aapaidinvoices",
        sorter: (a, b) => a.aapaidinvoices.length - b.aapaidinvoices.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "No of Paid Invoices",
        dataIndex: "count",
        key: "count",
        sorter: (a, b) => a.count.length - b.count.length,
        sortDirections: ["descend", "ascend"],
        align: "left",
      },
      
    ];
    const linecolumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      /*{
        title: "Contact Name",
        dataIndex: "contact_name",
        key: "contact_name",
      },*/
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
      },
      {
        title: "Amount",
        dataIndex: "total",
        key: "total",
      },
      {
        title: "Mode of Payment",
        dataIndex: "bank_name",
        key: "bank_name",
      },
      {
        title: "link",
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => a.link.length - b.link.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
      ];
    var TableData = computed(() =>
      state.XeroMode.data.map((contact) => {
        const {
          id,
          date,
          contact_name,
          invoice_no,
          invoice_id,
          subtotal,
          tax_amount,
          total,
          account_code,
          action_url,
          type,
          currency_code,
          children,
          count,
          aapaidinvoices,
        } = contact;
        var linedata = [];
        for (let i = 0; i < children.length; i++) {
          linedata.push({
              key: id,
              id: children[i].id, 
              date: children[i].date,
              contact_name:children[i].contact_name,
              invoice_id: children[i].invoice_id,
              invoice_no: children[i].invoice_no,
              total: children[i].total,
              type: children[i].type,
              status: children[i].status,
              bank_name: children[i].bank_name,
              link: (
              <div class="">
                <>
                  <a onClick={() => openurl(children[i].invoice_id,children[i].type)} to="#">
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ),
          action: (
              <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(children[i].invoice_id, "B")}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="info"
                      to="#"
                      id="dismiss_btn"
                    >
                      Dismiss
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
            });
        }
        return {
          key: invoice_id,
          id: id,
          date: date.replace('00:00:00',''),
          supplier: contact_name,
          invoice_no: invoice_no,
          subtotal: subtotal,
          tax_amount: tax_amount,
          total: total,
          account_code: account_code,
          invoice_id: invoice_id,
          action_url:action_url,
          currency_code:currency_code,
          type:type,
          count: <p>{count + " Transactions"}</p>,
          linedata: linedata,
          aapaidinvoices:aapaidinvoices,
        };
      })
    );

    var ignoreTableData = computed(() =>
      state.XeroMode.data.map((contact) => {
        const {
          id,
          date,
          contact_name,
          invoice_no,
          invoice_id,
          subtotal,
          tax_amount,
          total,
          account_code,
          action_url,
          type,
          currency_code,
          children,
          count,
          aapaidinvoices,
        } = contact;
        var linedata = [];
        for (let i = 0; i < children.length; i++) {
          linedata.push({
              key: id,
              id: children[i].id, 
              date: children[i].date,
              contact_name:children[i].contact_name,
              invoice_id: children[i].invoice_id,
              invoice_no: children[i].invoice_no,
              total: children[i].total,
              type: children[i].type,
              status: children[i].status,
              bank_name: children[i].bank_name,
              link: (
              <div class="">
                <>
                  <a onClick={() => openurl(children[i].invoice_id,children[i].type)} to="#">
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ),
          action: (
              <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(children[i].invoice_id, "A")}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="info"
                      to="#"
                      id="dismiss_btn"
                    >
                      Active
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
            });
        }
        return {
          key: invoice_id,
          id: id,
          date: date.replace('00:00:00',''),
          supplier: contact_name,
          invoice_no: invoice_no,
          subtotal: subtotal,
          tax_amount: tax_amount,
          total: total,
          account_code: account_code,
          invoice_id: invoice_id,
          action_url:action_url,
          currency_code:currency_code,
          type:type,
          count: <p>{count + " Transactions"}</p>,
          linedata: linedata,
          aapaidinvoices:aapaidinvoices,
        };
      })
    );

     const exportToCSV = () => {
      const inputData = state.XeroMode.data.map((contact) => {
        const { 
          id,
          date,
          contact_name,
          invoice_no,
          invoice_id,
          subtotal,
          tax_amount,
          total,
          account_code,
          type,
          currency_code,
          children,
          count,
          aapaidinvoices,} = contact;

        var linedata = [];
        for (let i = 0; i < children.length; i++) {
          linedata.push({
              key: id,
              id: children[i].id, 
              date: children[i].date,
              contact_name:children[i].contact_name,
              invoice_id: children[i].invoice_id,
              invoice_no: children[i].invoice_no,
              total: children[i].total,
              type: children[i].type,
              status: children[i].status,
              bank_name: children[i].bank_name,
            });
        }
        return {
          id: id,
          date: date.replace('00:00:00',''),
          contact_name: contact_name,
          invoice_no: invoice_no,
          subtotal: subtotal,
          tax_amount: tax_amount,
          total: total,
          type:type,
          data: linedata,
          count: count,
          aapaidinvoices: aapaidinvoices,
        };
      });

      var linedata = [];

      for (let i = 0; i < inputData.length; i++) {
        linedata.push({
          id: inputData[i]["id"],
          "Contact Name": inputData[i]["contact_name"],
          "Paid Invoices": inputData[i]["aapaidinvoices"],
          Transactions: inputData[i]["count"],
        });
        for (let j = 0; j < inputData[i]["data"].length; j++) {
          linedata.push({
            Date: inputData[i]["data"][j]["date"],
            "Invoice No":inputData[i]["data"][j]["invoice_no"],
            "Amount": inputData[i]["data"][j]["total"],
            "Mode of Payment": inputData[i]["data"][j]["bank_name"],
          });
        }
      }
      var linedata1 = [];
      linedata1.push({
      Point : "Payment Mode of Prevoiusly Paid Invoices",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      const fileName =
        orgdata.value.client_name +
        " Payment Mode of Prevoiusly Paid Invoices " +
        orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,linedata,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };

    return {
      loading,
      TableColumns,
      linecolumns,
      TableData,
      rowSelection,
      selectcount,
      ignoreTableData,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,
      DateSelect,
      addFormState,
      handleSearch,
      orgdata,
      exportToCSV,
      selectedOption1,
      controller,
    };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #968f8f34;
}
#dismiss_btn {
  color: white;
  background-color: #5f63f2;
}
</style>

<template>
  <div v-if="loading">
    <sdCards headless>
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
  </div>
  <div v-else>
    <sdPageHeader title="Help Documentation">
      <template v-slot:subTitle>
        
        <a-button
          size="default"
          type="text"
          style="
            border: none;
            vertical-align: middle;
            transform: rotateZ(270deg);
          "
        >
          <span>
            <sdFeatherIcons
              style="line-height: 0; color: black !important"
              type="bar-chart"
              size="18"
          /></span>
        </a-button>
      </template>
    </sdPageHeader>
    <Main>
      <div>
      <ul>
          <li><sdCards><sdHeading>1. View</sdHeading>
          <p>Shows selected client in Xero dashboard in new tab.</p>
          </sdCards></li>
          <li><sdCards><sdHeading>2. Remove Client</sdHeading>
          <p>Removes Connected client from Analytics dashboard and disconnect from Xero.</p>
          </sdCards></li>
          <li><sdCards><sdHeading>3. Unsync</sdHeading>
          <p>Removes all sync period and synced data from Analytics and resets client Sync status to Not Synced.</p>
          </sdCards></li>
          <li><sdCards><sdHeading>4. Sync</sdHeading>
          <p>This functionality used add parent sync period, This is a major period required by user to store clients data to database(takes time depends on data synced).</p>
          </sdCards></li>
          <li><sdCards><sdHeading>5. Report Sync</sdHeading>
          <p>This is inclusive period of synced period which is used to Review reports(If not added annexure will not show reports).</p>
          </sdCards></li>
          
      </ul>        
      </div>

    </Main>
  </div>
</template>
<script>
import { Main, TableWrapper } from "../styled";
import { computed, ref, defineComponent, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { UserTableStyleWrapper, Action, ProductCard } from "./style";
import * as FontAwesomeIcon from "@fortawesome/free-solid-svg-icons";
import Modal from "/src/components/Modal.vue";
export default defineComponent({
  name: "dashboard",
  components: {
    Main,
    TableWrapper,
    UserTableStyleWrapper,
    Modal,
    ProductCard,
    FontAwesomeIcon,
    Action,
  },
 
  setup() {
    let empty = ref(true);
    let loading = ref(true);
    let orgdata = ref(null);
    let showModal= ref(false);
    let reconnect=ref(false);
    const param = useRoute();
    const router = useRouter();
    const { state, dispatch } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    onMounted(() => {
      xerocheck();
    });
    var ten_id="";
    const openModal = (tenent_id,output) => {
    ten_id=tenent_id;
    var out=output;
    //alert(tenent_id);
    localStorage.setItem("ten_id", ten_id);
    localStorage.setItem("output", out);
    showModal.value=true;
    }
    const xerocheck = () => {
      if (param.params.id) {
        dispatch("xerosave", param.params.id.toString()).then(
          (response) => {
            router.push("/");
          },
          (error) => {
            // console.log(error);
            empty.value = true;
          }
        );
        empty.value=false;
        loading.value = false;
      } else {
        dispatch("getclientlist").then(
          (response) => {
            state.xeroclient.data = response.data;
            empty.value = false;
            loading.value = false;
          },
          (error) => {
            // console.log(error);
            empty.value = false;
            loading.value = false;
          }
        );
        
      }
    };

    const xerodisconnect = (tenent_id) => {
      dispatch("xerodisconnect",tenent_id).then(
        (response) => {
        location.reload(true);
          xerocheck();
        },
        (error) => {
        //window.location.reload(true);
          xerocheck();
          // console.log(error);
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        name: record.name,
      }),
    };

    const showEditModal = (data) => {
      console.log(data);
    };

    const onHandleDelete = (id) => {
      console.log(id);
    };

    const handlesort = () => {
      var data = {};
      dispatch("clientdatasort", data);
    };

    const overviewpage = (id) => {
      if (id !== undefined) {
        localStorage.setItem("org", id);
        router.push({ path: "/overview" });
      }
    };
    const startactive = (id, tenent_id) => {
      console.log(id);
      dispatch("onStarUpdate", { id: id, tenent_id: tenent_id }).then(
        (response) => {
          xerocheck();
        },
        (error) => {
          // console.log(error);
          empty.value = true;
        }
      );
    };
    var usersTableData = computed(() => state.xeroclient.data);

    const xeroconnect = () => {
      window.location.href = API_ENDPOINT + "xero/authorization.php?app_id=1";
    };

     const xeroResync = (tenent_id) => {

      dispatch("getResync",tenent_id).then(
        (response) => {
          location.reload()
        },
        (error) => {}
      );
    };
 const xeroreconnect = (tenent_id) => {
     dispatch("xerorefresh",tenent_id).then(
        (response) => {
        //location.reload(true);
        xerocheck();
        },
        (error) => {
        //window.location.reload(true);
          xerocheck();
          // console.log(error);
        }
      ); 
    };


    return {
      rowSelection,
      empty,
      usersTableData,
      showEditModal,
      showModal,
      openModal,
      orgdata,
      handlesort,
      startactive,
      overviewpage,
      xerodisconnect,
      xeroconnect,
      xeroreconnect,
      loading,
      xeroResync
    };
  },
});
</script>
<style>
.ant-list-items {
  padding-top: 0px !important;
}

.highlighted {
  margin: 10px !important;
  border-spacing: 0 8px !important;
}

.product-list:hover {
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
</style>